import { useContext } from "react";

import WebplaceContext from "../../../../store/webplace-context";

import ButtonFormat from "../../../shared/ButtonFormat/ButtonFormat";
import ImageCard from "../../../shared/ImageCards/ImageCard/ImageCard";
import "./InstagramCards.css";

const InstagramCards = () => {
  const instagramCtx = useContext(WebplaceContext).homeSocials;
  const stylesCtx = useContext(WebplaceContext).homeSocials.home_socials_styles;

  return (
    <div className="instagram-wrapper">
      <div className="instagram-title" style={{ color: stylesCtx.title_color }} role="heading" aria-level="2">
        {instagramCtx.home_instagram_title}
      </div>
      <div className="instagram-icons-wrapper">
        {instagramCtx.home_instagram_format.map((info, index) => {
          return (
            <div key={`igImg${index + 1}`}>

              {instagramCtx.home_instagram_format[index] !== "mp4" && <ImageCard
                imageUrl={require(`../../../../assets/instagramImages/ig${index + 1
                  }.${instagramCtx.home_instagram_format[index]}`)}
                index={index}
                noText={true}
              />}


              {instagramCtx.home_instagram_format[index] === "mp4" && <video
                autoPlay={true}
                muted
                playsInline
                controls
                loop
                className="video-ig"
                style={{ objectFit: "cover" }}
                aria-label="Instagram video"

              >
                <source src={require(`../../../../assets/instagramImages/ig${index + 1
                  }.${instagramCtx.home_instagram_format[index]}`)} type="video/mp4"
                />
                <track
                  kind="captions"
                  srclang="en"
                  label="English" />
              </video>}

            </div>
          );
        })}
      </div>
      <div className="btn-style">
        <ButtonFormat
          btnSize={stylesCtx.home_instagram_button_style.btn_size}
          showArrow={stylesCtx.home_instagram_button_style.show_arrow}
          arrowAtStart={stylesCtx.home_instagram_button_style.arrow_at_start}
          arrowColor={stylesCtx.home_instagram_button_style.arrow_color}
          showBorder={stylesCtx.home_instagram_button_style.show_border}
          borderColor={stylesCtx.home_instagram_button_style.border_color}
          backgroundColor={
            stylesCtx.home_instagram_button_style.background_color
          }
          fontColor={stylesCtx.home_instagram_button_style.font_color}
          capitalizeText={stylesCtx.home_instagram_button_style.capitalize_text}
          btnText={instagramCtx.home_instagram_button_text}
          url={instagramCtx.instagram_url}
        />
      </div>
    </div >
  );
};

export default InstagramCards;
